import { IconProps } from 'components/icons/types';

export const ArrowLeftIcon = (props: IconProps) => {
  const { width = 21, height = 20, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox='0 0 21 20' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path d='M16.3346 10.0013H4.66797M4.66797 10.0013L10.5013 15.8346M4.66797 10.0013L10.5013 4.16797'
            stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const ArrowLeftFreeIcon = (props: IconProps) => {
  const { width = 20, height = 20, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox='0 0 21 20' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <mask id='mask0_8431_71587' maskUnits='userSpaceOnUse' x='0' y='0' width={width} height={height}>
        <rect width='20' height='20' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_8431_71587)'>
        <path
          d='M9.00065 9.99984L12.2507 13.2498C12.4034 13.4026 12.4798 13.5971 12.4798 13.8332C12.4798 14.0693 12.4034 14.2637 12.2507 14.4165C12.0979 14.5693 11.9034 14.6457 11.6673 14.6457C11.4312 14.6457 11.2368 14.5693 11.084 14.4165L7.25065 10.5832C7.16732 10.4998 7.10829 10.4096 7.07357 10.3123C7.03885 10.2151 7.02148 10.1109 7.02148 9.99984C7.02148 9.88873 7.03885 9.78456 7.07357 9.68734C7.10829 9.59012 7.16732 9.49984 7.25065 9.4165L11.084 5.58317C11.2368 5.43039 11.4312 5.354 11.6673 5.354C11.9034 5.354 12.0979 5.43039 12.2507 5.58317C12.4034 5.73595 12.4798 5.93039 12.4798 6.1665C12.4798 6.40262 12.4034 6.59706 12.2507 6.74984L9.00065 9.99984Z'
          fill='#1C1B1F' />
      </g>
    </svg>
  );
};


export const ArrowRightFreeIcon = (props: IconProps) => {
  const { width = 20, height = 20, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox='0 0 21 20' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <mask id='mask0_8475_81552' maskUnits='userSpaceOnUse' x='0' y='0' width={width} height={height}>
        <rect x='0.5' width='20' height='20' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_8475_81552)'>
        <path
          d='M11.0007 9.99984L7.75065 6.74984C7.59787 6.59706 7.52148 6.40262 7.52148 6.1665C7.52148 5.93039 7.59787 5.73595 7.75065 5.58317C7.90343 5.43039 8.09787 5.354 8.33398 5.354C8.5701 5.354 8.76454 5.43039 8.91732 5.58317L12.7507 9.4165C12.834 9.49984 12.893 9.59012 12.9277 9.68734C12.9625 9.78456 12.9798 9.88873 12.9798 9.99984C12.9798 10.1109 12.9625 10.2151 12.9277 10.3123C12.893 10.4096 12.834 10.4998 12.7507 10.5832L8.91732 14.4165C8.76454 14.5693 8.5701 14.6457 8.33398 14.6457C8.09787 14.6457 7.90343 14.5693 7.75065 14.4165C7.59787 14.2637 7.52148 14.0693 7.52148 13.8332C7.52148 13.5971 7.59787 13.4026 7.75065 13.2498L11.0007 9.99984Z'
          fill='white' />
      </g>
    </svg>

  );
};
